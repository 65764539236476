import React, { useContext, useEffect, useState } from 'react'
import './Dashboard.css'
import DashboardLayout from './DashboardLayout'
import CheckBox from '../../product/components/atoms/CheckBox'
import CommonContext from '../../custom/context/common/commonContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../product/common/components'
import UserContext from '../../product/context/user/userContext'
import AuthContext from '../../product/context/auth/authContext'
import { ListItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'

function Notifications() {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)

    const userContext = useContext(UserContext)

    const [reload, setReload] = useState(false)

    const { user, loadUser } = authContext
    const { allNotifications } = commonContext
    const { updatePreference, responseStatus: responseStatusUser } = userContext

    const formikPreference = useFormik({
        initialValues: {
            email_prefer: [],
            sms_prefer: [],
        },
        onSubmit: (values) => {
            updatePreference(values)
        },
    })

    useEffect(() => {
        if (user) {
            console.log('user details here ', user)
            formikPreference.values.email_prefer =
                user.email_notification && user.email_notification.split(',').length
                    ? user.email_notification.split(',')
                    : []
            formikPreference.values.sms_prefer =
                user.sms_notification && user.sms_notification.split(',').length
                    ? user.sms_notification.split(',')
                    : []
            setReload(!reload)
        }
    }, [user])

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updatePreference') {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                }
            }
        }
    }, [responseStatusUser])

    return (
        <DashboardLayout title={'My Notifications'}>
            <div className="dashboardInner notifications">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/profile">
                            {'Profile'}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/change">
                            {'Change Password'}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/account/notifications">
                            {'My Notifications'}
                        </NavLink>
                    </ListItem>
                </div>
                {/* <h5 className="dashSubtitle">{'Dash Subtitle'}</h5> */}
                <div className="table-responsive dpTable">
                    <form onSubmit={formikPreference.handleSubmit} autoComplete="nofill">
                        <table className="table text-left">
                            <thead>
                                <tr>
                                    <th scope="col">{'Notification'}</th>
                                    <th scope="col">{'Email'}</th>
                                    <th scope="col">{'SMS'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allNotifications.map((data, index) => (
                                    <>
                                        <tr key={data.id}>
                                            <td>{data.description}</td>
                                            <td hidden={data.email == 0 ? true : false}>
                                                <div hidden={data.email == 0 ? true : false}>
                                                    <CheckBox
                                                        name={'email_prefer'}
                                                        label={''}
                                                        checked={
                                                            formikPreference.values[
                                                                'email_prefer'
                                                            ].indexOf(data.id.toString()) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                        value={data.id.toString()}
                                                        onChange={formikPreference.handleChange}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div hidden={data.sms == 0 ? true : false}>
                                                    <CheckBox
                                                        name={'sms_prefer'}
                                                        label={''}
                                                        checked={
                                                            formikPreference.values[
                                                                'sms_prefer'
                                                            ]?.indexOf(data.id.toString()) !== -1
                                                                ? true
                                                                : false
                                                        }
                                                        value={data.id.toString()}
                                                        onChange={formikPreference.handleChange}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>
                        <PrimaryButton type="submit" label={'Save'} />
                    </form>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Notifications
