import React from 'react'
import { Toolbar, Select, MenuItem, Typography, Box } from '@material-ui/core'
import moment from 'moment'

const CustomToolbar = ({ date, onChange, maxDate, pickerProps }) => {
    const safeDate = moment.isMoment(date) && date.isValid() ? date : moment()

    const safeMaxDate =
        moment.isMoment(maxDate) && maxDate.isValid() ? maxDate : moment('01/01/2100')

    const currentYear = moment().year()
    const maxYear = safeMaxDate.year()
    const years = Array.from({ length: maxYear - (currentYear - 100) + 1 }, (_, i) => maxYear - i)

    const months = moment.months()
    const filteredMonths =
        safeDate.year() === maxYear ? months.slice(0, safeMaxDate.month() + 1) : months

    const handleYearChange = (event) => {
        event.stopPropagation()
        event.preventDefault()
        const newDate = safeDate.clone().year(event.target.value)
        onChange(newDate)

        console.log('pickerProps handleYearChange', pickerProps)
        if (pickerProps && pickerProps.setOpen) {
            pickerProps.setOpen(true)
        }
    }

    const handleMonthChange = (event) => {
        event.stopPropagation()
        event.preventDefault()
        const newDate = safeDate.clone().month(event.target.value)
        onChange(newDate)
        console.log('pickerProps handleMonthChange', pickerProps)
        if (pickerProps && pickerProps.setOpen) {
            pickerProps.setOpen(true)
        }
    }

    return (
        <Toolbar style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
            <Typography variant="h6">Select Date</Typography>

            <Box display="flex" gap={2}>
                <Select
                    value={safeDate.year()}
                    onChange={handleYearChange}
                    variant="standard"
                    MenuProps={{ disablePortal: true }}
                >
                    {years.map((year) => (
                        <MenuItem key={year} value={year}>
                            {year}
                        </MenuItem>
                    ))}
                </Select>

                <Select
                    value={safeDate.month()}
                    onChange={handleMonthChange}
                    variant="standard"
                    MenuProps={{ disablePortal: true }}
                >
                    {filteredMonths.map((month, index) => (
                        <MenuItem key={month} value={index}>
                            {month}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
        </Toolbar>
    )
}

export default CustomToolbar
