import React, { useRef, useEffect, useState } from 'react'
import { CKEditor as CKEditor5 } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import FormHelperText from '@material-ui/core/FormHelperText'
import uploader from '../../../common/ckeditor-uploader'
import { Tooltip, IconButton } from '@material-ui/core'
import CustomDialog from '../../organisms/Dialog/index'

const CustomCkeditor = ({
    formik,
    name,
    label,
    lableInfo,
    tooltiptitle,
    disabled,
    className,
    config,
    contents,
    lableInfoNoTooltip,
}) => {
    const [toggleDialog, setToggleDialog] = useState(false)
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }
    return (
        <div className={className}>
            {label && (
                <label
                    className={`${
                        disabled ? 'datePickerLabels' : 'datePickerLabel'
                    } text-left w-100`}
                >
                    {label}
                    {lableInfo && (
                        <Tooltip title={tooltiptitle}>
                            <IconButton>
                                <span className="material-icons">info</span>
                            </IconButton>
                        </Tooltip>
                    )}
                    {lableInfoNoTooltip ? (
                        <IconButton>
                            <span
                                className="material-icons"
                                onClick={() => {
                                    if (contents) {
                                        setToggleDialog(true)
                                    }
                                }}
                            >
                                info
                            </span>
                        </IconButton>
                    ) : null}
                </label>
            )}

            <CKEditor5
                editor={ClassicEditor}
                config={
                    config || {
                        htmlSupport: {
                            allow: [
                                {
                                    name: /.*/,
                                    attributes: true,
                                    classes: true,
                                    styles: true,
                                },
                            ],
                        },
                    }
                }
                onReady={(editor) => {
                    if (editor)
                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
                            new uploader(loader)
                    return editor
                }}
                disabled={disabled}
                data={formik.values[name]} // Sync with Formik value
                onChange={(event, editor) => {
                    formik.setFieldValue(name, editor.getData() || ' ')
                }}
            />

            <div className="checkboxError">
                <FormHelperText>
                    {formik.errors[name] && formik.touched[name] && formik.errors[name]}
                </FormHelperText>
            </div>

            <CustomDialog
                title={
                    <div className="bidConfirmTitle">
                        {tooltiptitle}
                        <div className="ssmHead d-flex justify-content-center">
                            <button
                                type="button"
                                className="close"
                                id="cancel"
                                onClick={() => setToggleDialog(false)}
                            >
                                <span className="material-icons">clear</span>
                            </button>
                        </div>
                    </div>
                }
                open={toggleDialog}
                function={changeDialogStatus}
                className="bidConfirmationCstm"
            >
                <div className="">
                    {contents
                        ? contents
                              .replace(/\$enter/g, '\n')
                              .split('\n')
                              .map((line, index) => <p key={index}>{line}</p>)
                        : null}
                </div>
            </CustomDialog>
        </div>
    )
}

export default CustomCkeditor
