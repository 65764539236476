import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import {
    FormControl,
    IconButton,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    Tooltip,
} from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
// import CustomDialog from '@/custom/components/organisms/CustomDialog'
import Dialog from '../../../components/organisms/Dialog'

const CustomSelect = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'

    const [toggleDialog, setToggleDialog] = useState(false)
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    function groupedElements() {
        let elements = []

        if (props.groupoptions?.length) {
            props.groupoptions.forEach((optGroup, optGroupIndex) => {
                elements.push(
                    <ListSubheader key={`subheader-${optGroupIndex}`}>
                        {optGroup.head}
                    </ListSubheader>,
                )

                optGroup.options.forEach((opt, optIndex) => {
                    elements.push(
                        <MenuItem
                            key={`menuItem-${optIndex}`}
                            value={opt.value || ''}
                            name={opt.value}
                        >
                            {opt.show}
                        </MenuItem>,
                    )
                })
            })
        }

        return elements
    }

    return (
        <div className={`customSelect ${props.className}`}>
            {props.upperLabel ? (
                <>
                    <label htmlFor={props.id}>
                        {props.label}
                        {props?.lableInfo ? (
                            <Tooltip title={props?.tooltiptitle} placement={props.placement}>
                                <IconButton>
                                    <span
                                        className={
                                            props?.outLinedIcon
                                                ? 'material-icons-outlined'
                                                : 'material-icons'
                                        }
                                        onClick={() => {
                                            if (props?.contents) {
                                                setToggleDialog(true)
                                            }
                                        }}
                                    >
                                        info
                                    </span>
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </label>

                    <TextField
                        id={props.id}
                        select
                        size={props.size}
                        SelectProps={{
                            native: true,
                        }}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: shrink,
                        }}
                        variant="outlined"
                        disabled={props.disabled}
                        name={props.name}
                        value={props.value}
                        autoFocus={props.autoFocus}
                        onChange={props.onChange}
                        error={props.error}
                        helperText={props.helperText}
                    >
                        {props.children}
                    </TextField>
                </>
            ) : (
                <>
                    {props.customizedDropdown ? (
                        <FormControl className={props.className} fullWidth>
                            <InputLabel
                                id={props.id}
                                shrink={props.shrink}
                                className="MuiInputLabel-outlined"
                            >
                                {props.label}
                            </InputLabel>
                            <Select
                                value={props.value || ''}
                                onChange={props.onChange}
                                inputProps={{
                                    name: props.name,
                                    id: props.id,
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                size={props.size}
                                fullWidth
                                margin="normal"
                                variant={`${props.variant ? props.variant : 'outlined'}`}
                                disabled={props.disabled}
                                name={props.name}
                                ariaLabel={props.name}
                                error={props.error}
                                helperText={props.helperText}
                            >
                                {props.groupoptions != undefined ? groupedElements() : null}
                                {props.options?.length > 0 &&
                                    props.options?.map((opt, optindex) => (
                                        <MenuItem key={optindex} value={opt.value} name={opt.value}>
                                            {opt.show}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText className={props.error ? 'Mui-error' : ''}>
                                {props.helperText}
                            </FormHelperText>
                        </FormControl>
                    ) : (
                        <TextField
                            id={props.id}
                            select
                            size={props.size}
                            label={props.label}
                            SelectProps={{
                                native: true,
                            }}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: shrink,
                                'aria-label': props.label,
                                'aria-labelledby': props.id,
                            }}
                            variant={`${props.variant ? props.variant : 'outlined'}`}
                            disabled={props.disabled}
                            name={props.name}
                            ariaLabel={props.name}
                            value={props.value}
                            autoFocus={props.autoFocus}
                            onChange={props.onChange}
                            error={props.error}
                            helperText={props.helperText}
                        >
                            {props.children}
                        </TextField>
                    )}
                </>
            )}

            <Dialog
                title={
                    <div className="bidConfirmTitle">
                        {props?.tooltiptitle}
                        <div className="ssmHead d-flex justify-content-center">
                            <button
                                type="button"
                                className="close"
                                id="cancel"
                                onClick={() => setToggleDialog(false)}
                            >
                                <span className="material-icons">clear</span>
                            </button>
                        </div>
                    </div>
                }
                open={toggleDialog}
                function={changeDialogStatus}
                className="bidConfirmationCstm"
            >
                <div className="">
                    {props?.contents
                        ? props?.contents
                              .replace(/\$enter/g, '\n')
                              .split('\n')
                              .map((line, index) => <p key={index}>{line}</p>)
                        : null}
                </div>
            </Dialog>
        </div>
    )
}

export default CustomSelect
