import * as Yup from 'yup'
import {
    dateTimeFormatFunction,
    currencyFormatWithoutCurrency,
    dateFormatFunction,
    formatPhone,
    formatDate,
} from '../../../../common/components'
import Cryptos from '../../../../common/cryptos'

export const validationFunction = (arrayValue, customValidation, t) => {
    let handle = {}

    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            // 'date','month','time'

            if (customValidation && customValidation[data.name]) {
                handle[data.name] = customValidation[data.name]
            } else {
                if (
                    data.type === 'radio' ||
                    data.type === 'text' ||
                    data.type === 'email' ||
                    data.type === 'select' ||
                    data.type === 'searchdrop'
                ) {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required(t('Required!'))
                    } else {
                        handle[data.name] = Yup.string()
                    }
                }

                if (data.name === 'password') {
                    handle[data.name] = Yup.string()
                        .min(8, t('Minimum 8 characters'))
                        .required(t('Required!'))
                }

                if (data.name === 'confirm_password') {
                    handle[data.name] = Yup.string()
                        .oneOf([Yup.ref('password')], t('Passwords not match'))
                        .required(t('Required!'))
                }

                if (data.type === 'checkboxarray') {
                    if (data.is_required) {
                        handle[data.name] = Yup.array().nullable().required(t('Required!'))
                    } else {
                        handle[data.name] = Yup.array().nullable()
                    }
                }

                if (data.type === 'textarea') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().nullable().required(t('Required!'))
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }

                if (data.type === 'check') {
                    if (data.is_required) {
                        handle[data.name] = Yup.array().nullable().required(t('Required!'))
                    } else {
                        handle[data.name] = Yup.array().nullable()
                    }
                }

                if (data.type === 'phone') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().nullable().required(t('Required!'))
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }
                if (data.type === 'date') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required(t('Required!')).nullable()
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }

                if (data.type === 'time') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required(t('Required!')).nullable()
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }

                if (data.type === 'datetime') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required(t('Required!')).nullable()
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }

                if (data.type === 'checkbox') {
                    if (data.is_required) {
                        handle[data.name] = Yup.boolean().required(t('Required!'))
                    } else {
                        handle[data.name] = Yup.boolean()
                    }
                }

                if (data.type === 'multiselect') {
                    if (data.is_required) {
                        handle[data.name] = Yup.array().required(t('Required'))
                    } else {
                        handle[data.name] = Yup.array()
                    }
                }

                if (data.type === 'number') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required(t('Required'))
                    } else {
                        handle[data.name] = Yup.string()
                    }
                }

                if (data.type === 'ckeditor') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required(t('Required'))
                    } else {
                        handle[data.name] = Yup.string()
                    }
                }
                if (data.type === 'float') {
                    if (data.is_required) {
                        handle[data.name] = Yup.number()
                            .required(t('Required'))
                            .test('is-decimal', 'invalid decimal', (value) =>
                                (value + '').match(/^\d*\.{1}\d*$/),
                            )
                    } else {
                        handle[data.name] = Yup.number().test(
                            'is-decimal',
                            'invalid decimal',
                            (value) => (value + '').match(/^\d*\.{1}\d*$/),
                        )
                    }
                }

                if (data.type === 'uploadDropZone') {
                    if (data.is_required) {
                        handle[data.name] = Yup.array().required(t('Required!'))
                    } else {
                        handle[data.name] = Yup.array()
                    }
                }

                if (data.type === 'switch') {
                    if (data.is_required) {
                        handle[data.name] = Yup.boolean().required(t('Required!'))
                    } else {
                        handle[data.name] = Yup.boolean()
                    }
                }
                if (data.type === 'multipleArrayValue') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required(t('Required'))
                    } else {
                        handle[data.name] = Yup.string()
                    }
                }

                // if (data.child) {
                //     if (data.is_required) {
                //         handle[data.name] = Yup.string().when(data.parent_element, {
                //             is: true,
                //             then: Yup.string().required('Required!'),
                //             otherwise: Yup.string(),
                //         })
                //     }
                // }
            }
        })

    return handle
}

export const initialFunction = (
    arrayValue,
    alldata_all_dropdown,
    subLevelOptions,
    allCountries,
    subStateOptions,
    subCityOptions,
    allLocations,
    indexValue,
    props,
) => {
    let show = {}
    let tempMapData = []
    let calculateValues = []
    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            let mapValue = {}

            if (data.type !== 'default' && data.type !== 'custom') {
                mapValue.name = data.name
                mapValue.type = data.type
                mapValue.label = data.label
                mapValue.errorLabel = data.errorLabel
                mapValue.placeholder = data.placeholder
                mapValue.class = data.class
                mapValue.content = data.content
                mapValue.disabled = data.disabled
                mapValue.calculate = data.calculate
                mapValue.calculate_value = data.calculate_value
                mapValue.icon_only = data.icon_only
                mapValue.retrieve = data.retrieve
                mapValue.customDateFormat = data?.customDateFormat
                mapValue.countryCodeEditable = data?.countryCodeEditable
                mapValue.disablePast = data?.disablePast
                mapValue.disableFuture = data?.disableFuture
                mapValue.displayFlag = data?.displayFlag
                mapValue.upperLabel = data?.upperLabel
                mapValue.showTodayButton = data?.showTodayButton
                mapValue.customToolBar = data?.customToolBar
                mapValue.autoOk = data?.autoOk
                mapValue.stepper = data?.stepper
                mapValue.rows = data?.rows
                mapValue.maxDate = data?.maxDate

                //  mapValue.child = data.childy
                //  mapValue.parent_element = data.parent_element
            } else {
                mapValue = null
            }
            if (data?.endAdornment === 'info') {
                mapValue.endAdornment = data?.endAdornment
                mapValue.tooltiptitle = data?.tooltiptitle
            }
            if (data?.lableInfo) {
                mapValue.lableInfo = data?.lableInfo

                mapValue.tooltiptitle = data?.tooltiptitle
                mapValue.contents = data?.contents
            }
            if (data?.lableInfoNoTooltip) {
                mapValue.lableInfoNoTooltip = data?.lableInfoNoTooltip
                mapValue.tooltiptitle = data?.tooltiptitle
                mapValue.contents = data?.contents
            }
            if (data?.defaultUpload) {
                mapValue.defaultImages = data?.defaultImages
            }
            if (data.type == 'ckeditor5') {
                if (data.config) {
                    mapValue.config = data.config
                }
            }

            if (data.type == 'hiddenMask') {
                mapValue.tooltiptitle = 'Please click on the edit icon to enable the field.'
                mapValue.disableHoverListener = false
                mapValue.last4Masked = true
                mapValue.customEndAdornmentOff = 'edit'
                mapValue.customEndAdornmentOn = 'edit_off'
            }

            if (data.type == 'uploadDropZone') {
                mapValue.folder = data.folder || 'product'
                mapValue.multiple = data.multiple || false
                mapValue.reorder = data.reorder || false
                mapValue.accept = data.accept || ''
                mapValue.titleText = data.label
                mapValue.htmlText = data.htmlText
                mapValue.innerText = data.placeholder
                mapValue.object_image = data.object_image || 0
                mapValue.is_compress = data.is_compress === 1 ? 'true' : 0 || 0
                mapValue.compress_quality = data.compress_quality || 0
                mapValue.compress_width = data.compress_width || 0
                mapValue.compress_height = data.compress_height || 0
                if (data.multi_field) {
                    mapValue.name = `${data.name}_${indexValue}`
                }
                mapValue.customized_uploader = data.customized_uploader || false
                mapValue.avatarMode = data.avatarMode ?? false
            }

            if (data.type == 'multipleArrayValue') {
                mapValue.fields = data.fields
                mapValue.subname = data.subname
            }

            if (data.type === 'searchdrop') {
                mapValue.getAllOptions = data.getAllOptions
                mapValue.options = data.options
                mapValue.forcePopupIcon = data?.forcePopupIcon
                mapValue.focused = data?.focused
            }
            if (data.type == 'select' && parseInt(data.location, 10) === 1) {
                console.log('kooo', allLocations)
                if (data.customSelect) {
                    mapValue.customSelect = 1
                }
                if (data.customizedDropdown) {
                    mapValue.customizedDropdown = 1
                }
                if (props?.customLocationFilter?.key && props?.customLocationFilter?.value) {
                    mapValue.options = allLocations
                        ?.filter(
                            (value) =>
                                value.active === 1 &&
                                value[props?.customLocationFilter?.key] ===
                                    props?.customLocationFilter?.value,
                        )
                        .map((location) => {
                            let locationChanged = {}
                            locationChanged.show = props?.customDescription
                                ? location[props?.customDescription]
                                : location.description
                            locationChanged.value = location.id
                            return locationChanged
                        })
                } else if (data.seller && data.user_id) {
                    mapValue.options = allLocations
                        .filter((value) => value.active === 1 && value.user_id == data.user_id)
                        .map((location) => {
                            let locationChanged = {}
                            locationChanged.show = location.description || location.name
                            locationChanged.value = location.id
                            return locationChanged
                        })
                } else {
                    mapValue.options = allLocations
                        ?.filter((value) => value.active === 1)
                        .map((location) => {
                            let locationChanged = {}
                            locationChanged.show = props?.customDescription
                                ? location[props?.customDescription]
                                : location.description
                            locationChanged.value = location.id
                            return locationChanged
                        })
                }
            }

            if (
                data.type === 'select' &&
                parseInt(data.sub_select, 10) !== 1 &&
                data.location !== 1
            ) {
                console.log(data.name, 'select')
                if (data.options) {
                    mapValue.options = data.options
                } else {
                    if (data.customizedDropdown) {
                        mapValue.customizedDropdown = 1
                    }
                    mapValue.options = alldata_all_dropdown
                        .filter((value) => value.variable === data.name && value.level === 0)
                        .sort((a, b) => a.show_order - b.show_order)
                        .map((item) => {
                            let valueChanged = {}
                            valueChanged.show = item.description
                            valueChanged.value = data.diff_value ? item.value : item.id
                            return valueChanged
                        })
                }
            }
            if (data.type === 'radio') {
                mapValue.int = 1
            }
            if (
                data.type === 'checkboxarray' ||
                (data.type === 'radio' && data?.options?.length > 0)
            ) {
                mapValue.item = data?.options
                    ? data?.options?.map((item) => {
                          let valueChanged = {}
                          valueChanged.description = item.description
                          valueChanged.id = item.id
                          return valueChanged
                      })
                    : alldata_all_dropdown
                          .filter((value) => value?.variable === data.name)
                          .map((item) => {
                              let valueChanged = {}
                              valueChanged.description = item.description
                              valueChanged.id = item.id
                              return valueChanged
                          })
            } else if (data.type === 'checkboxarray' || data.type === 'radio') {
                mapValue.item = alldata_all_dropdown
                    .filter((value) => value.variable === data.name)
                    .map((item) => {
                        let valueChanged = {}
                        valueChanged.description = item.description
                        valueChanged.id = item.id
                        return valueChanged
                    })
            }

            if (data.type === 'multiselect' && data.name !== 'project_id') {
                mapValue.options = alldata_all_dropdown
                    .filter((value) => value.variable === data.name)
                    .map((item) => {
                        let valueChanged = {}
                        valueChanged.show = item.description
                        valueChanged.value = item.id
                        return valueChanged
                    })
            }

            if (data.type === 'multiselect' && data.name === 'project_id') {
                mapValue.options = data.options
            }
            if (data.calculate) {
                calculateValues.push(data)
            }

            // 'date','month','time'
            if (data.initialDefault) {
                show[data.name] = data.initialDefault
            } else {
                if (
                    data.type === 'radio' ||
                    data.type === 'text' ||
                    data.type === 'email' ||
                    data.type === 'select' ||
                    data.type === 'searchDrop'
                ) {
                    if (parseInt(data.country, 10) === 1) {
                        show[data.name] = global?.defaultCountry ? global.defaultCountry : ''
                    } else {
                        show[data.name] = ''
                    }
                }

                if (data.name === 'password') {
                    show[data.name] = ''
                }

                if (data.name === 'confirm_password') {
                    show[data.name] = ''
                }

                if (data.type === 'checkboxarray') {
                    show[data.name] = []
                }

                if (data.type === 'textarea') {
                    show[data.name] = ''
                }

                if (data.type === 'check') {
                    show[data.name] = []
                }

                if (data.type === 'phone') {
                    show[data.name] = ''
                }

                if (data.type === 'datetime') {
                    show[data.name] = null
                }
                if (data.type === 'date') {
                    show[data.name] = null
                }

                if (data.type === 'time') {
                    show[data.name] = null
                }

                if (data.type === 'checkbox') {
                    show[data.name] = false
                }

                if (data.type === 'multiselect') {
                    show[data.name] = []
                }

                if (data.type === 'number' || data.type === 'float') {
                    show[data.name] = ''
                }

                if (data.type === 'ckeditor') {
                    show[data.name] = ''
                }

                if (data.type === 'uploadDropZone') {
                    console.log(data.multi_field, 'llll')
                    if (data.multi_field) {
                        show[`${data.name}_${indexValue}`] = []
                    } else {
                        show[data.name] = []
                    }
                }

                if (data.type === 'switch') {
                    show[data.name] = false
                }

                if (data.type === 'default') {
                    show[data.name] = data.value
                }

                if (data.type === 'custom') {
                    show[data.name] = data.value
                }
                if (data.type === 'multipleArrayValue') {
                    show[data.name] = []
                }
            }

            if (data.type === 'select' && parseInt(data.sub_select, 10) === 1) {
                mapValue.sub_select = data.sub_select
                mapValue.sub_level = data.sub_level
                mapValue.sub_field = data.sub_field
                console.log(alldata_all_dropdown, 'optttt')
                console.log(data.name, 'dataaa')
                if (data.customizedDropdown) {
                    mapValue.customizedDropdown = 1
                }
                if (parseInt(data.sub_level, 10) === 0) {
                    if (data.customSelect) {
                        let variable = data.name.split('_')
                        variable.splice(variable.length - 1, 1)
                        variable = variable.join('_')
                        mapValue.options = alldata_all_dropdown
                            .filter(
                                (maincat) => maincat.variable === variable && maincat.level === 0,
                            )
                            .map((maincat) => {
                                let categoryChanged = {}
                                categoryChanged.show = maincat.description
                                categoryChanged.value = maincat.id
                                return categoryChanged
                            })
                    } else {
                        mapValue.options = alldata_all_dropdown
                            .filter(
                                (maincat) => maincat.variable === data.name && maincat.level === 0,
                            )
                            .map((maincat) => {
                                let categoryChanged = {}
                                categoryChanged.show = maincat.description
                                categoryChanged.value = maincat.id
                                return categoryChanged
                            })
                        console.log(mapValue.options, 'opppp')
                    }
                } else if (parseInt(data.sub_level, 10) > 0) {
                    mapValue.options = subLevelOptions(data)
                }
            }

            if (data.type == 'select' && parseInt(data.country, 10) === 1) {
                if (data.customizedDropdown) {
                    mapValue.customizedDropdown = 1
                }
                let sortedData
                mapValue.options = allCountries
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    })
                if (global.countryOrder) {
                    sortedData = mapValue.options
                    global.countryOrder.map((e) => {
                        const object1 = sortedData.find((obj) => obj.value === e)
                        if (object1) {
                            // Remove objects from their current positions
                            sortedData.splice(sortedData.indexOf(object1), 1)
                            sortedData.unshift(object1)
                        }
                    })
                    mapValue.options = sortedData
                }
            }

            if (data.type == 'select' && parseInt(data.state, 10) === 1) {
                if (data.customizedDropdown) {
                    mapValue.customizedDropdown = 1
                }
                mapValue.options = subStateOptions(data)
            }

            if (data.type == 'select' && parseInt(data.city, 10) === 1) {
                if (data.customizedDropdown) {
                    mapValue.customizedDropdown = 1
                }
                mapValue.options = subCityOptions(data)
            }

            if (mapValue) {
                tempMapData.push(mapValue)
            }
        })

    return { tempMapData, show, calculateValues }
}

export const initialSearchFunction = (arrayValue) => {
    let show = {}
    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            if (data.name) {
                show[data.name] = {}
            }

            if (data.type === 'text') {
                show[data.name].value = []
                show[data.name].type = 'array'
                if (data.is_default) {
                    show[data.name].field = data.field || `p.${data.name}`
                    show[data.name].title = data.title || `${data.name}`
                } else {
                    show[data.name].field = `it.${data.name}`
                }
            }

            if (data.type === 'select') {
                show[data.name].value = []
                show[data.name].type = 'array'
                if (data.is_default) {
                    show[data.name].field = data.field || `p.${data.name}`
                    show[data.name].title = data.title || `${data.name}`
                } else {
                    show[data.name].field = `it.${data.name}`
                }
            }
            if (data.type === 'multiselect') {
                show[data.name].value = []
                show[data.name].type = 'findinarray'
                if (data.is_default) {
                    show[data.name].field = `${data.field}` || `p.${data.name}`
                    show[data.name].title = `${data.title}` || `${data.name}`
                } else {
                    show[data.name].field = `it.${data.name}`
                }
            }
            if (data.type === 'checkboxarray') {
                show[data.name].value = []
                show[data.name].type = 'findinarray'
                if (data.is_default) {
                    show[data.name].field = `${data.field}` || `p.${data.name}`
                    show[data.name].title = `${data.title}` || `${data.name}`
                } else {
                    show[data.name].field = `it.${data.name}`
                }
            }
            if (data.type === 'checkbox' || data.type === 'radio') {
                if (data.name === 'wprice') {
                    show[data.name].value = 0
                    show[data.name].type = 'greaterequal'
                } else {
                    show[data.name].value = ''
                    show[data.name].type = 'array'
                }

                if (data.is_default) {
                    show[data.name].field = data.field || `p.${data.name}`
                    show[data.name].title = data.title || `${data.name}`
                } else {
                    show[data.name].field = `it.${data.name}`
                }
            }
            // if (data.type === 'time') {
            //     show[data.name].value = ''
            //     if (data.name === 'slot_from_time') {
            //         show[data.name].type = 'greaterequal'
            //     } else {
            //         show[data.name].type = 'smallerequal'
            //     }
            //     if (data.is_default) {
            //         show[data.name].field = `${data.field}` || `p.${data.name}`
            // show[data.name].title = `${data.title}` || `${data.name}`
            //     } else {
            //         show[data.name].field = `it.${data.name}`
            //     }
            // }
            if (data.type === 'date') {
                show[data.name].value = []
                show[data.name].type = 'array'
                if (data.is_default) {
                    show[data.name].field = `${data.field}` || `p.${data.name}`
                    show[data.name].title = `${data.title}` || `${data.name}`
                } else {
                    show[data.name].field = `it.${data.name}`
                }
            }
            if (data.type === 'arraylike') {
                show[data.name].value = []
                show[data.name].type = 'arraylike'
                if (data.is_default) {
                    show[data.name].field = `${data.field}` || `p.${data.name}`
                    show[data.name].title = `${data.title}` || `${data.name}`
                } else {
                    show[data.name].field = `it.${data.name}`
                }
            }
        })
    console.log(show, 'showww')
    return show
}

export const initialFilterFunction = (arrayValue) => {
    let show = {}
    function setTableShortValue(data) {
        switch (data.view_page) {
            case '1':
                if (data.is_default) {
                    show[data.field].field = `p.${data.field}`
                } else {
                    show[data.field].field = `it.${data.field}`
                }
                break
            case '2':
                if (data.is_default) {
                    show[data.field].field = `ac.${data.field}`
                } else {
                    show[data.field].field = `cac.${data.field}`
                }
                break
            case '3':
                if (data.is_default) {
                    show[data.field].field = `u.${data.field}`
                } else {
                    show[data.field].field = `cu.${data.field}`
                }
                break

            default:
                break
        }
    }

    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            show[data.field] = {}
            show[data.field].type = data.filter_type

            if (data.type === 'text') {
                show[data.field].value = ''
                setTableShortValue(data)
            }

            if (data.type === 'datetime') {
                show[data.field].value = ''
                setTableShortValue(data)
            }
        })

    return show
}

export const editValue = (arrayValue, editData, indexValue, tableName = '', props) => {
    console.log(arrayValue, editData, indexValue, 'array======')
    const objectPush = {}
    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            objectPush.id = editData.id
            objectPush.multiArrayObject = editData.multipleArrayValue
                ? tableName
                    ? editData.multipleArrayValue[tableName].data
                    : editData.multipleArrayValue.damage_items.data
                : []
            if (data.type != 'misc') {
                if (data.initialDefault && !editData[data.name]) {
                    objectPush[data.name] = data.initialDefault
                } else {
                    if (data.type == 'imagewithurl' || data.type == 'uploadDropZone') {
                        if (data.multi_field) {
                            objectPush[`${data.name}_${indexValue}`] = editData[data.name]
                                ? editData[data.name].toString().split(',')
                                : []
                        } else if (data.object_image) {
                            objectPush[data.name] = editData[data.name]
                                ? JSON.parse(editData[data.name].toString())
                                : []
                        } else {
                            objectPush[data.name] = editData[data.name]
                                ? editData[data.name].toString().split(',')
                                : []
                        }
                    } else if (data.type == 'datetime') {
                        objectPush[data.name] = editData[data.name]
                            ? dateTimeFormatFunction(editData[data.name], true)
                            : null
                    } else if (data.type == 'date') {
                        if (props?.formatCustomDate) {
                            objectPush[data.name] = editData[data.name]
                                ? formatDate(editData[data.name], 'MM-dd-yyyy', true)
                                : null
                        } else {
                            objectPush[data.name] = editData[data.name]
                                ? dateFormatFunction(editData[data.name], true)
                                : null
                        }
                    } else if (data.type == 'number') {
                        if (
                            props?.formatPhone &&
                            props?.phoneField?.length &&
                            props?.phoneField?.includes(data.name)
                        ) {
                            objectPush[data.name] = formatPhone(editData[data.name], true)
                        } else {
                            objectPush[data.name] = editData[data.name] ? editData[data.name] : 0
                        }

                        // else if (data.type == 'phone') {
                        //     console.log(
                        //         formatPhone(editData[data.name], true),
                        //         data.name,
                        //         'formatPhone(editData[data.name], true)',
                        //     )
                        //     objectPush[data.name] = global.formatPhone
                        //         ? formatPhone(editData[data.name], true)
                        //         : editData[data.name]
                        // }
                    } else if (data.type == 'numbercomma') {
                        objectPush[data.name] = editData[data.name]
                            ? currencyFormatWithoutCurrency(editData[data.name])
                            : 0
                    } else if (data.type == 'checkbox') {
                        objectPush[data.name] = editData[data.name] ? editData[data.name] : false
                    } else if (data.type == 'multiselect' && data.name === 'project_id') {
                        objectPush[data.name] = editData.auction_lots
                            ? editData.auction_lots.split(',').map(Number)
                            : []
                    } else if (
                        data.type == 'multiselect' &&
                        data.name !== 'project_id' &&
                        data.name === 'delivery_not_available'
                    ) {
                        objectPush[data.name] = editData[data.name]
                            ? editData[data.name].split(',')?.map((value) => value.trim())
                            : []
                    } else if (
                        data.type == 'multiselect' &&
                        data.name !== 'project_id' &&
                        data.name !== 'delivery_not_available'
                    ) {
                        objectPush[data.name] = editData[data.name]
                            ? editData[data.name].split(',').map(Number)
                            : []
                    } else if (data.type == 'custom') {
                        objectPush[data.name] = data.value
                    } else if (data.type == 'multipleArrayValue') {
                        objectPush[data.name] = editData[data.name] ? editData[data.name] : []
                    } else if (data.type == 'hiddenMask') {
                        objectPush[data.name] = editData[data.name]
                            ? Cryptos.decrypt(editData[data.name]) || ''
                            : ''
                    } else {
                        if (props?.formatPhone && props?.phoneField?.length) {
                            props?.phoneField.map((_data) => {
                                if (data.name === _data) {
                                    objectPush[data.name] = formatPhone(editData[data.name], true)
                                } else {
                                    objectPush[data.name] = editData[data.name]
                                        ? editData[data.name]
                                        : ''
                                }
                            })
                        } else {
                            objectPush[data.name] = editData[data.name] ? editData[data.name] : ''
                        }
                    }
                }
            }
        })
    return objectPush
}
