import React, { useContext, useState, useEffect } from 'react'
import CustomDialog from '../../../../components/organisms/Dialog'
import PrimaryButton from '../../../atoms/PrimaryButton/index'
import Button from '@material-ui/core/Button'
import UserContext from '../../../../context/user/userContext'
import { handleRedirectInternal, mapData } from '../../../../common/components'
import Loaders from '../../../molecules/Loaders'
import CustomCommonContext from '../../../../../custom/context/common/commonContext'
import AlertContext from '../../../../context/alert/alertContext'
import AuthContext from '../../../../context/auth/authContext'
import { useTranslation } from 'react-i18next'

export default function PhoneValidationComponent(props) {
    const phoneVerify = props.phoneVerify
    const changePhoneVerify = props.changePhoneVerify
    const formik = props.formik
    const setPhoneVerify = props.setPhoneVerify
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { t } = useTranslation()
    const [disable, setDisable] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const userContext = useContext(UserContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { configVariables } = customCommonContext
    const authContext = useContext(AuthContext)
    const {
        sendPhoneLoginCode,
        responseStatus: responseStatusAuth,
        clearResponse,
        login,
    } = authContext
    const {
        sendPhoneVerifyCode,
        verifyPhoneVerifyCode,
        responseStatus: responseStatusUser,
    } = userContext

    const sendVerifyCode = () => {
        setIsloading(true)
        if (props.isLogin) {
            sendPhoneLoginCode({
                phone: props?.includeCountryCode
                    ? `${props?.includeCountryCode}${formik.values.phone}`
                    : formik.values.phone,
            })
        } else {
            sendPhoneVerifyCode({
                id: formik.values.id,
                phone: props?.includeCountryCode
                    ? `${props?.includeCountryCode}${formik.values.phone}`
                    : formik.values.phone,
                isRegister: formik.values.isRegister,
                isForgotPassword: formik.values.isForgotPassword,
                isVerifyPhone: formik.values.isVerifyPhone,
            })
        }
    }
    const successVerifyCode = () => {
        setTimeout(() => {
            setIsloading(false)
        }, 500)
        formik.setFieldValue('phone_verified', 1)
        formik.setFieldValue('verified_phonenum', formik.values.phone)
        if (props.profileUpdate) {
            props.profileUpdate()
        } else {
            if (props.verifyStatusChange) {
                formik.setFieldValue('status', 'active')
            }
        }
        formik.values.verify_code = ''
        setPhoneVerify(false)
    }

    const verifyPhoneCode = () => {
        setIsloading(true)
        if (
            configVariables?.static_otp &&
            parseInt(configVariables.static_otp, 10) === parseInt(formik.values?.verify_code, 10)
        ) {
            successVerifyCode()
        } else {
            if (formik.values?.verify_code && formik.values?.verify_code !== '') {
                if (props.isLogin) {
                    login({
                        phone: props?.includeCountryCode
                            ? `${props?.includeCountryCode}${formik.values.phone}`
                            : formik.values.phone,
                        verify_code: formik.values?.verify_code,
                        otpLogin: true,
                    })
                } else {
                    verifyPhoneVerifyCode(
                        {
                            phone: props?.includeCountryCode
                                ? `${props?.includeCountryCode}${formik.values.phone}`
                                : formik.values.phone,
                            verify_code: formik.values?.verify_code,
                        },
                        props.customVerifyPhoneVerifyCode,
                    )
                }
            } else {
                setAlert(t('Please enter code'), 'error')
                setIsloading(false)
            }
        }
    }

    const verifyPhone = [
        {
            label: 'Verification Code',
            placeholder: 'Enter Verification Code',
            class: 'col-12',
            type: 'otpInput',
            name: 'verify_code',
            formik: formik,
            separator: ' ',
            placeholder: '000000',
        },
    ]

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'sendPhoneVerifyCode') {
                    setTimeout(() => {
                        setIsloading(false)
                    }, 500)
                    formik.setFieldValue('phone_verifysent', 1)
                }
                if (
                    responseStatusUser.from === 'verifyPhoneVerifyCode' ||
                    responseStatusUser.from === 'customVerifyPhoneVerifyCode'
                ) {
                    successVerifyCode()
                }
            } else {
                setTimeout(() => {
                    setIsloading(false)
                }, 500)
            }
        }
        setDisable(false)
    }, [responseStatusUser])
    useEffect(() => {
        if (responseStatusAuth) {
            if (responseStatusAuth.status === 'success') {
                if (responseStatusAuth.from === 'sendPhoneLoginCode') {
                    setTimeout(() => {
                        setIsloading(false)
                    }, 500)
                    formik.setFieldValue('phone_verifysent', 1)
                }
                if (responseStatusAuth.from === 'verifyPhoneVerifyCode') {
                    successVerifyCode()
                }
            } else {
                setTimeout(() => {
                    setIsloading(false)
                }, 500)
            }
            clearResponse()
        }
        setDisable(false)
    }, [responseStatusAuth])
    useEffect(() => {
        if (formik.values.phone_verifysent) {
            formik.setFieldValue('phone_verifysent', 0)
            formik.setFieldValue('phone_verified', 0)
            if (props.verifyStatusChange) {
                formik.setFieldValue('status', 'email_unverified')
            }
        }
    }, [formik.values.phone])

    return (
        <>
            <CustomDialog
                className="verifyPhone"
                // title={'Verify Phone'}
                open={phoneVerify}
                handleClose={() => changePhoneVerify()}
                // function={changePhoneVerify}
            >
                {isLoading ? (
                    <Loaders name="home" isLoading={isLoading} />
                ) : (
                    <>
                        {formik.values.phone_verifysent === 1 ? (
                            <>
                                <h4 className="vpTitle">
                                    {t('We have sent a confirmation code to')} {formik.values.phone}
                                    .
                                </h4>
                                <br />
                            </>
                        ) : (
                            <h4 className="vpbTitle">
                                {t('A confirmation code will be sent to')}{' '}
                                <span>{formik.values.phone}.</span>
                                <div className="clearfix"></div>
                                {t('Please confirm if your number is correct')}.
                            </h4>
                        )}

                        {formik.values.phone_verifysent === 1 && (
                            <>
                                <div className="row phVerInp mb-2">
                                    {Object.values(mapData(verifyPhone))}
                                </div>
                                <span className="phVer mb-2 d-block">
                                    * {t('Your code will expire in 5 minutes')}.
                                </span>
                                <PrimaryButton
                                    id="phoneCode"
                                    onClick={verifyPhoneCode}
                                    label={t('VERIFY CODE')}
                                />
                            </>
                        )}
                        <div className="actionWrapper secondaryActions">
                            {/* <Button onClick={() => changePhoneVerify()}>{'Cancel'}</Button> */}
                            {formik.values.phone_verifysent === 1 ? (
                                <Button
                                    id="resend_sms"
                                    disabled={disable}
                                    className="resendSms"
                                    onClick={() => {
                                        setDisable(true)
                                        sendVerifyCode()
                                    }}
                                >
                                    {t('RESEND SMS')}
                                </Button>
                            ) : (
                                <PrimaryButton
                                    id="verifyCode"
                                    label={t('SEND SMS')}
                                    onClick={() => sendVerifyCode()}
                                />
                            )}
                        </div>

                        {props?.showContactUs && props?.contactUsLink ? (
                            <div className="text-center">
                                <a
                                    href={
                                        props?.contactUsLink ? props?.contactUsLink : '/contact-us'
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'underline', color: 'blue' }}
                                >
                                    {t(props?.contactUsText ? props?.contactUsText : 'Contact Us')}
                                </a>
                            </div>
                        ) : null}
                    </>
                )}
            </CustomDialog>
        </>
    )
}
